import React from "react";
import { Link } from "react-router-dom";

export default function Navbar({ lang, Categories, linkNavigation }) {
  return (
    <>
      
        <nav className="navbar-expand-lg bg-light" id="nav1">
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <Link className="navbar-brand navImage" to={"/"}>
              <img src={require("../../assets/logo.png")} alt="pageLogo"/>
            </Link>
            <button
              className="navbar-toggler border-1 border-light"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-light fs-1"><i className="bi bi-list text-dark"></i></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#Home"
                  >
                    {lang == "en" ? "Home " : "الرئيسية"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#AboutUs"
                  >
                    {lang == "en" ? "About Us" : "عن الشركة"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#OurServices"
                  >
                    {lang == "en" ? "Services" : "خدماتنا"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#OurGallery"
                  >
                    {lang == "en" ? "Product’s Categories" : "اقسام المنتجات"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#OurDatesJourney"
                  >
                    {lang == "en" ? "Coffee Journey" : "رحلتنا مع حبوب القهوة"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#OurMissinAndOurVision"
                  >
                    {lang == "en" ? "Missin And Vision" : "مهمتنا و رؤيتنا"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6
                    className="nav-link link-light"
                    onClick={(e) => {
                      linkNavigation(e);
                    }}
                    to="#ContactUs"
                  >
                    {lang == "en" ? "Countact Us" : "تواصل معنا"}
                  </h6>
                </li>
                <li className="nav-item px-2">
                  <h6>
                    <Link
                      className="nav-link link-light mt-0"
                      to={"/checkadmin"}
                    >
                      {lang == "en" ? "Admin Page" : " صفحة الادمن"}
                    </Link>
                  </h6>
                </li>
              </ul>
            </div>
          </div>
          <ul className="d-flex list-unstyled justify-content-end mb-0">
              <li className="nav-item px-2">
                <h4 className="nav-link text-dark">
                  <i className="fa-solid fa-globe"></i>
                </h4>
              </li>
              <li className="nav-item px-2">
                <h4
                  onClick={() => {
                    localStorage.setItem("lang", "ar");
                    window.location.reload();
                  }}
                  className="nav-link text-dark"
                >
                  العربية
                </h4>
              </li>
              <li className="nav-item px-2">
                <h4
                  onClick={() => {
                    localStorage.setItem("lang", "en");
                    window.location.reload();
                  }}
                  className="nav-link text-dark"
                >
                  English
                </h4>
              </li>
          </ul>
        </nav>
        <nav className="navbar navbar-expand-lg" id="nav2">
          <div className="container">
            <div className="w-100 px-3">
              <ul className="list-unstyled mb-2 mb-lg-0 text-white categoryLinksContainer row d-flex justify-content-around align-items-center">
                {Categories
                  ? Categories.ctegories.map((category) => (
                      <li className="nav-item px-2 col-3" key={category._id}>
                        <Link
                          className="nav-link  link-dark categoryLinks"
                          to={`/category/${category._id}`}
                        >
                          {category.name}
                        </Link>
                      </li>
                    ))
                  : "Loading ....."}
              </ul>
            </div>
          </div>
        </nav>
    </>
  );
}
