import React from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
export default function Footer({lang , Categories , linkNavigation }) {

  function copyText(id) {
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(`#${id}`).text()).select();
    document.execCommand("copy");
    $temp.remove();
  }
  return <>
        <footer id='footer'>
            <div className="container py-5">
                <div className="row gy-4">
                  <div id='footerContact' className="col-lg-8 col-md-12 col-12 px-lg-5">
                    <div className='p-3 text-center'>
                        <h3>{lang == 'en' ? 'Contact information' : 'معلومات التواصل'}</h3>
                        {lang == 'en' ? <>
                          <div className="d-flex align-items-center mb-2">
                            <div className='footerIcons'><i className="fa-solid fa-phone"></i></div>
                            <div className='pt-3 ps-2'><p><span className='fw-bold'>Customers-Service/ Mobile / WhatsApp :</span> <span id='mobileNumberEn'>+201142812850</span> <i style={{cursor:'pointer'}} title='copy' onClick={()=>{copyText('mobileNumberEn')}} className="bi bi-copy"></i></p></div>
                          </div>
                          <div className="d-flex align-items-center mb-2">
                            <div className='footerIcons'><i className="fa-solid fa-envelope"></i></div>
                            <div className='pt-3 ps-2'><p><span className='fw-bold'>Email :</span> <span id='emailEn'>Rashad@interco-trade.com</span> <i style={{cursor:'pointer'}} title='copy' onClick={()=>{copyText('emailEn')}} className="bi bi-copy"></i></p></div>
                          </div>                          
                          <div className="d-flex align-items-center mb-2">
                            <div className='footerIcons'><i className="fa-solid fa-location-dot"></i></div>
                            <div className='pt-3 ps-2 text-start'><p><span className='fw-bold'>Address :</span> Mit Hibash  El-bahria, Tanta, Gharbia, Egypt.</p></div>
                          </div>
                        </> : <>
                          <div className="d-flex align-items-center flex-row-reverse justify-content-end text-end  mb-2">
                              <div className='footerIcons'><i className="fa-solid fa-phone"></i></div>
                              <div className='pt-3 pe-2 ms-auto'><p><i style={{cursor:'pointer'}} title='copy' onClick={()=>{copyText('mobileNumberAr')}} className="bi bi-copy"></i> <span id='mobileNumberAr'>+201142812850</span> <span className='fw-bold'> :  خدمة العملاء / الواتساب / الموبايل</span></p></div>
                            </div>
                            <div className="d-flex align-items-center flex-row-reverse justify-content-end text-end mb-2">
                              <div className='footerIcons'><i className="fa-solid fa-envelope"></i></div>
                              <div className='pt-3 pe-2 ms-auto'><p> <i style={{cursor:'pointer'}} title='copy' onClick={()=>{copyText('emailAr')}} className="bi bi-copy"></i> <span id='emailAr'>Rashad@interco-trade.com</span> <span className='fw-bold'> :البريد الالكتروني</span></p></div>
                            </div>                          
                            <div className="d-flex align-items-center flex-row-reverse justify-content-end text-end mb-2">
                              <div className='footerIcons'><i className="fa-solid fa-location-dot"></i></div>
                              <div className='pt-3 pe-2 ms-auto'><p>. <span className='fw-bold'> العنوان: </span>ميت حبيش البحرية - طنطا - غربيه -مصر </p></div>
                            </div>
                        </>}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-12">
                    <div id='footerMainLinks' className='p-3 text-center'>
                        <h3>{lang == 'en' ? 'MainLinks' : 'الاجزاء الرئيسية'}</h3>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#Home">{lang == 'en'?'Home ': 'الرئيسية'}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#AboutUs">{lang == 'en' ? "About Us" :"عن الشركة"}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#OurServices">{lang == 'en' ? "Our Services" :"خدماتنا"}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#OurGallery">{lang == 'en' ? "Our Gallery" :"معرضنا"}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#OurDatesJourney">{lang == "en" ? "Coffee Journey" : "رحلتنا مع حبوب القهوة"}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#OurMissinAndOurVision">{lang == 'en' ? "OurMissin And OurVision" :"مهمتنا و رؤيتنا"}</h6></h6>
                        <h6><h6 className="nav-link" onClick={(e) => {linkNavigation(e)}} to="#CountactUs">{lang == 'en' ? "Countact Us" :"تواصل معنا"}</h6></h6>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-12">
                    <div className='p-3 text-center'>
                        <h3>{lang == 'en' ? 'Categories' : 'صفحات المنتجات'}</h3>
                        {Categories?Categories.ctegories.map(category => <h6 key={category._id}><Link className="nav-link link-light"  to={`/category/${category._id}`}>{category.name}</Link></h6>):''}                       
                    </div>
                  </div>

                </div>
            </div>
        </footer>
  </>
}
