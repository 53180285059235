import React from 'react'

export default function AboutUsSection({lang}) {
  return <>
        <section id='AboutUs'>
            <div className='container py-5'>
                <div className="row gx-0 containerBox rounded-3 bg-white shadow-lg">
                    <div className="col-12 text-center">
                        <div className='py-5 px-2'>
                            {lang == 'en' ? <>
                                <h2 className='mb-5'>International For Trading and Import</h2>
                                <p className='mb-2'>International For Trading and Import is located in Delta region at the heart of Egypt in Tanta City, Gharbia Province. Our team has gained over the years of experience in the international trading import and export on Food supplies in FMCG, frozen stuff, Pickles, nuts, Grains, Dates and Raw Coffee beans from different origins.
                                    We contract directly from the greatest farmers, producers and traders   companies located all over the world.
                                </p>
                                <p>
                                    we have large variety of products which is selected carefully based on quality with competitive prices.
                                </p>
                            </>:<>
                                <h2 className='mb-5'> الدولية للتجارة و الاستيراد </h2>
                                <p>تقع مؤسسة الدولية التجارية  التجارية في منطقة الدلتا بقلب جمهورية مصر العربية اكتسب فريقنا على مدى سنوات من الخبرة في مجال التجارة الدولية والاستيراد والتصدير  للإمدادات الغذائية في السلع الاستهلاكية سريعة الحركة والمواد المجمدة والمخللات والمكسرات والحبوب والتمور وحبوب البن الخام من أصول مختلفة. نحن نتعاقد مباشرة مع أكبر شركات المزارعين والمنتجين والتجار الموجودة في جميع أنحاء العالم. ولدينا مجموعة كبيرة ومتنوعة من المنتجات التي تم اختيارها بعناية على أساس الجودة وبأسعار تنافسية</p>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </>
}
